import FormValidation from './FormValidation';
import { Home as HomeIcon } from '@styled-icons/boxicons-regular/Home';
import { Envelope as ContactIcon } from '@styled-icons/boxicons-solid/Envelope';

const toKey = (el) =>
  el
    .toString()
    .toLowerCase()
    .replace(/[,\s]+|[/_/]+/g, '-');

const stripTags = (str) => str.replace(/(<([^>]+)>)/gi, '');

const getNavItemProps = (key) => {
  const mapping = {
    home: {
      name: 'Home',
      title: 'Back to htmlene home page',
      to: '/',
      renderIcon: () => <HomeIcon size={24} alt="Home Icon" />,
    },
    about: {
      name: 'About',
      title: 'About Helene Malespine aka htmlene',
      to: './about',
    },
    work: {
      name: 'Work Samples',
      title: 'See htmlene Work Samples',
      to: './work-samples',
    },
    skillsset: {
      name: 'Skills Set',
      title: 'View Helene Malespine Skills Set',
      to: './skills-set',
    },
    contact: {
      name: 'Contact',
      title: 'Contact Helene Malespine for a Front-end developer role',
      to: './contact',
      renderIcon: () => <ContactIcon size={24} alt="Contact Icon" />,
    },
  };
  return mapping[key];
};

/* eslint-disable no-bitwise */
const getNuance = (color, amt) => {
  const num = parseInt(color.slice(1), 16);

  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
};

const getThemeColors = (color) => {
  return {
    main: color,
    light: getNuance(color, 20),
    dark: getNuance(color, -20),
  };
};

const getThemeObj = (name) => {
  const mapping = {
    htmlcss: {
      title: 'HTML & CSS',
      color: getThemeColors('#ff5252'),
    },
    js: {
      title: 'JS and libraries',
      color: getThemeColors('#ff8251'),
    },
    design: {
      title: 'UI / UX',
      color: getThemeColors('#e14275'),
    },
    dataviz: {
      title: 'Data Visualization',
      color: getThemeColors('#b44589'),
    },
    testing: {
      title: 'Unit and Integration tests',
      color: getThemeColors('#7f4a8c'),
    },
    performance: {
      title: 'Performance monitoring',
      color: getThemeColors('#4d497e'),
    },
    workflow: {
      title: 'Workflow',
      color: getThemeColors('#294162'),
    },
  };

  return mapping[name];
};

const getPageAnimationVariants = (flow, size, rect) => {
  const { effect } = flow;
  const { width, height, left, top } = rect;

  const screenWidth = size.width;
  const screenHeight = size.height;
  const startingXScale = width / screenWidth;
  const startingYScale = height / screenHeight;

  const animation =
    effect === 'scale'
      ? {
          start: {
            zIndex: 1,
            x: left,
            y: top,
            scale: `${startingXScale}, ${startingYScale}`,
            originX: 0,
            originY: 0,
            overflow: 'hidden',
          },
          end: { zIndex: 1, x: 0, y: 0, scale: '1, 1', overflow: 'visible' },
          exit: {
            zIndex: 1,
            x: left,
            y: top,
            scale: `${startingXScale}, ${startingYScale}`,
            originX: 0,
            originY: 0,
            overflow: 'hidden',
          },
        }
      : {
          start: { opacity: 0 },
          end: { opacity: 1 },
          exit: { opacity: 0 },
        };

  return {
    start: animation.start,
    end: animation.end,
    exit: animation.exit,
  };
};

export {
  FormValidation,
  toKey,
  stripTags,
  getThemeObj,
  getNavItemProps,
  getPageAnimationVariants,
};
