import { atom } from 'recoil';

const pageFlow = atom({
  key: 'pageFlow',
  default: { origin: 'home', target: '', effect: 'fade' },
});

const positionStart = atom({
  key: 'positionStart',
  default: {},
});

const windowSize = atom({
  key: 'windowSize',
  default: {
    width: process.browser ? window.innerWidth : 0,
    height: process.browser ? window.innerHeight : 0,
  },
});

const snackbarState = atom({
  key: 'snackbarState',
  default: {
    isOpen: false,
    message: '',
    error: false,
  },
});

export { pageFlow, positionStart, windowSize, snackbarState };
