class Validation {
  rules = {
    // eslint-disable-next-line max-len
    phoneRegex: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
    emailRegex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    nameRegex: /^[a-z ,.'-]+$/i,
  };

  /**
   * check the rule is active or not
   * @param {string}
   * @return {boolean}
   */
  isRule = (value, rules) => rules.split('|').indexOf(value) !== -1;

  /**
   * @return {boolean}
   */
  isValid = (val, rules) => {
    // Required validation
    if (this.isRule('required', rules) && val === '') {
      return false;
    }

    // Email validation
    if (this.isRule('email', rules) && !this.rules.emailRegex.test(val)) {
      return false;
    }

    // Name validation
    if (
      this.isRule('name', rules) &&
      (val.length < 2 || !this.rules.nameRegex.test(val))
    ) {
      return false;
    }

    // Message validation
    if (this.isRule('message', rules) && val.length < 10) {
      return false;
    }

    // Phone number validation
    if (this.isRule('phone', rules) && !this.rules.phoneRegex.test(val)) {
      return false;
    }

    return true;
  };
}

export default new Validation();
